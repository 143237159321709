const user = require('./modules/user.api')
const role = require('./modules/role.api')
const menu = require('./modules/menu.api')
const dict = require('./modules/dict.api')
const dept = require('./modules/dept.api')
const rule = require('./modules/rule.api')
const mark = require('./modules/mark.api')
const dictData = require('./modules/dict-data.api')
const file = require('./modules/file.api')
const article = require('./modules/article.api')
const paper = require('./modules/paper.api')
const question = require('./modules/question.api')
const video = require('./modules/video.api')
const videoDetail = require('./modules/video-detail.api')
const answer = require('./modules/answer.api')
const answerDetail = require('./modules/answer-detail.api')
const swiper = require('./modules/swiper.api')
const data = require('./modules/data.api')
const book = require('./modules/book.api')
const policy = require('./modules/policy.api')
const leaveWord = require('./modules/leave-word.api')

const api = {
    user,
    role,
    menu,
    dict,
    dept,
    rule,
    mark,
    dictData,
    file,
    article,
    paper,
    question,
    video,
    videoDetail,
    answer,
    answerDetail,
    swiper,
    data,
    book,
    policy,
    leaveWord,
}
import Vue from "vue";

Vue.prototype.$api = api

export default api
