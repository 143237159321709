export default {
    dialogType: {
        VIEW: 1,
        ADD: 2,
        EDIT: 3,
        TIP: 4
    },
    adminCode: 'admin',
    provideType: {
        VALUE: 1,
        METHOD: 2,
    },

}
