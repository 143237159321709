import request from "@/utils/request";

const prefix = '/data'

/**
 * 最近{dayNum}天的登录次数统计
 * @param dayNum
 * @param headers
 * @returns {*}
 */
export const recentlyLogin = (dayNum, headers = {}) => request({
    url: prefix + '/recentlyLogin/' + dayNum,
    method: 'get',
    headers
})

/**
 * 文章阅读{top}列表
 * @param top
 * @param headers
 * @returns {*}
 */
export const mostViewTop = (top, headers = {}) => request({
    url: prefix + '/mostViewTop/' + top,
    method: 'get',
    headers
})
