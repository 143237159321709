import Vue from 'vue'
import App from './App.vue'
import router from '@/router/index.js'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/api'
import '@/store'
import 'element-ui/lib/theme-chalk/base.css';
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
import Fragment from 'vue-fragment'
import "@/utils/permission";
import has from "@/utils/permission";
import globalConst from "@/utils/globalConst"
import dict from '@/components/DictData'// 数据字典
import { resetForm, dialog , addDateRange, handleSort} from "@/utils/common";
//import uploader from 'vue-simple-uploader';
//import VideoPlayer from 'vue-video-player/src';
//import 'vue-video-player/src/custom-theme.css'
//import 'video.js/dist/video-js.css'
import * as echarts from 'echarts'
import 'remixicon/fonts/remixicon.css'

Vue.prototype.$gc = globalConst
Vue.prototype.$resetForm = resetForm
Vue.prototype.$dialog = dialog
Vue.prototype.$addDateRange = addDateRange
Vue.prototype.$handleSort = handleSort
Vue.prototype.$echarts = echarts;

Vue.use(has)
Vue.use(dict)

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(Fragment.Plugin)
//Vue.use(uploader);
//Vue.use(VideoPlayer)

Vue.component(CollapseTransition.name, CollapseTransition)

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
